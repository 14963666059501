//// SPLENDORR CODE

// import smoothscroll from './assets/vendor/smoothscroll.js';
// smoothscroll.polyfill();

let breaksm = 426;
let breakxs = 590;
let breakmd = 760;
let breakxm = 1000;
let breaklg = 1275;
let breakxl = 1560;

let audioContext = false;

document.addEventListener('DOMContentLoaded', function () {
  // resize();
}); // end DOMContentLoaded / onReady

window.addEventListener('load', function (event) {
  pageLoad();
  console.log("All resources finished loading!");
});

window.onresize = _.debounce(() => {
  console.log('resized!')
  // createProjectTimelines();
  createProjectPopoutTimelines();
  if (app.activeindex !== -1) {
    app.toggleIndex(app.activeindex);
  }
}, 100)

// function checkAndUnmuteAudio() {
//   if (!audioContext ) {
//     console.log('no audioContext, creating')
    
//   }
//   if (audioContext && audioContext.state !== 'running') {
//     console.log('unmuting audioContext');
//   }
// }

function pageLoad() {

  // var countdown = function(value) {
  //   if (value > 0) {
  //     console.log(value);
  //     return countdown(value - 1);
  //   } else {
  //     return value;
  //   }
  // };
  // countdown(10);

  // checkAndUnmuteAudio();
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  unmute(audioContext);
  
  console.log('pageLoad');
  // console.log('window.innerHeight:', window.innerHeight);
}

let controller = new ScrollMagic.Controller();

let headerDiv, teamDiv, projDiv, floatingOnWaterDiv;
let headerTargets, projTargets, teamTargets, floatingOnWaterTargets;

let headerAnims = {};
let teamAnims = {};
let projAnims = {};
let floatingOnWaterAnims = {};
headerAnims['isPlaying'] = false;
headerAnims['name'] = 'headerAnims';
teamAnims['isPlaying'] = false;
teamAnims['name'] = 'teamAnims';
projAnims['isPlaying'] = false;
projAnims['name'] = 'projAnims';
floatingOnWaterAnims['isPlaying'] = false;
floatingOnWaterAnims['name'] = 'floatingOnWaterAnims';

var isInViewport = function (elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.top <= (window.innerHeight || document.documentElement.clientHeight) ||
    bounding.bottom <= 0 &&
    bounding.bottom >= (window.innerHeight || document.documentElement.clientHeight)
    // bounding.left >= 0 &&
    // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    // bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

function playConstants(obj, name) {
  console.log('playing: ', name);
  obj.isPlaying = true;
  _.each(obj, function(value, i) {
    if (i !== 'isPlaying' && typeof(value) == 'object') {
      // console.log('each loop playing: ', i);
      value.play();
    }
  });
}
function pauseConstants(obj, name) {
  console.log('pausing: ', name);
  obj.isPlaying = false;
  _.each(obj, function(value, i) {
    if (i !== 'isPlaying' && typeof(value) == 'object') {
      // console.log('each loop pausing: ', i);
      value.pause();
    }
  });
}

function randomizeThenDrift(targets, anims, container, horiz, vert, rndm = true) {
  anime({
    targets: targets,
    translateX: function() {
      if (rndm) {
        return anime.random(-1 * horiz, horiz) + '%';
      } else {
        return '0%';
      }
    },
    translateY: function() {
      if (rndm) {
        return anime.random(-1 * vert, vert) + '%';
      } else {
        return '0%';
      }
    },
    easing: 'linear',
    duration: 0,
    complete: function(anim) {
      _.each(targets, function(value, i) {
        driftLoop(value, anims, i, horiz, vert);
      });
      if (isInViewport(container)) {
        console.log(`${container} is in viewport`);
      } else {
        console.log(`${container} is NOT in viewport`);
        pauseConstants(anims, anims.constructor.name)
      }
    }
  });
}

function driftLoop(target, group, index, horiz, vert) {
  // console.log('driftLoop', group, index);
  // // This SHOULD kill the existing animation and prevent doubling up
  anime.remove(target);
  // group[index]
  group[index] = anime({
    targets: target,
    translateX: function() {
      return anime.random(-1 * horiz, horiz) + '%';
    },
    translateY: function() {
      return anime.random(-1 * vert, vert) + '%';
    },
    easing: 'easeInOutSine',
    // delay: anime.random(0, 150),
    duration: anime.random(3500, 6500),
    complete: function(anim) {
      // console.log('driftLoop complete: ', anim.animatables[0].target)
      driftLoop(anim.animatables[0].target, group, index, horiz, vert);
    }
  });
}

function wiggleLoop(target, group, index, direction) {
  let dir;
  if (!direction) {
    dir = (Math.random() < 0.5) ? -1 : 1
  } else {
    dir = (direction >= 0) ? -1 : 1
  }
  group[index] = anime({
    targets: target,
    rotate: function() {
      return anime.random(3, 5) * dir + 'deg';
    },
    easing: 'easeInOutSine',
    duration: anime.random(2500,3500),
    complete: function(anim) {
      wiggleLoop(anim.animatables[0].target, group, index, dir);
    }
  });
}

let headerScene, teamScene, projScene, floatingOnWaterScene;

// let sceneTeam = new ScrollMagic.Scene({
//   triggerElement: '.teamContainer', triggerHook: 'onEnter', duration: '200%', offset: '-50'
// })
// .on("progress", function (e) {
//   if (!teamAnims.isPlaying) {
//     playConstants(teamAnims, 'teamAnims')
//   }
// })
// .on("enter leave", function (e) {
//   console.log('scrollmagic event: ', e.type)
//   e.type == "enter" ? playConstants(teamAnims, 'teamAnims') : pauseConstants(teamAnims, 'teamAnims');
// })
// .addTo(controller);

// function createScrollScenes() {
//   projScene = new ScrollMagic.Scene({
//     triggerElement: '.projectsContainer', triggerHook: 'onEnter', duration: '200%', offset: '-50'
//   })
//   .on("progress", function (e) {
//     if (!projAnims.isPlaying) {
//       playConstants(projAnims, 'projAnims')
//     }
//   })
//   .on("enter leave", function (e) {
//     console.log('scrollmagic event: ', e.type)
//     e.type == "enter" ? playConstants(projAnims, 'projAnims') : pauseConstants(projAnims, 'projAnims');
//   })
//   .addTo(controller);
// }

// EX: createScrollScene(projScene, projAnims, projDiv)
function createScrollScene(sceneDiv, anims, container, dur = '200%') {
  // randomizeThenDrift(targets, anims, container, horiz, vert)
  sceneDiv = new ScrollMagic.Scene({
    triggerElement: container, triggerHook: 'onEnter', duration: dur, offset: '-50'
  })
  .on("progress", function (e) {
    if (!anims.isPlaying) {
      playConstants(anims, anims.name)
    }
  })
  .on("enter leave", function (e) {
    console.log('scroll event: ', e.type)
    e.type == "enter" ? playConstants(anims, anims.name) : pauseConstants(anims, anims.name);
  })
  .addTo(controller);
}

const animeScrollTo = (element) => {
  // const elementSelector = document.querySelector(element)
  // const elementOffset = elementSelector.getBoundingClientRect().top

  const top = element.getBoundingClientRect().top;
  const bottom = element.getBoundingClientRect().bottom;
  const elemHeight = top - bottom;


  const elementOffset = element.getBoundingClientRect().top
  const scrollPosition = window.scrollY
  const documentTop = document.documentElement.clientTop

  const winHeight = window.innerHeight;

  const scrollOffset = elementOffset - elemHeight/2 + scrollPosition - documentTop - (winHeight/2);

  anime({
    targets: [document.documentElement, document.body],
    scrollTop: scrollOffset,
    duration: 800,
    easing: 'easeInOutQuad'
  });
}

function hideProjectWithSafeguard(i) {
  if (!projectPopoutTimelines[i].reveal.paused) {
    projectPopoutTimelines[i].reveal.pause();
    projectPopoutTimelines[i].reveal.seek(0);
  }
  if (!projectPopoutTimelines[i].revealCentered.paused) {
    projectPopoutTimelines[i].revealCentered.pause();
    projectPopoutTimelines[i].revealCentered.seek(0);
  }
  projectPopoutTimelines[i].hide.play();
  projectPopoutTimelines[i].hideCentered.play();
  console.log('closing the open project: ', i);
}

function revealProjectWithSafeguard(i) {
  if (!projectPopoutTimelines[i].hide.paused) {
    projectPopoutTimelines[i].hide.pause();
    projectPopoutTimelines[i].hide.seek(0);
  }
  if (!projectPopoutTimelines[i].hideCentered.paused) {
    projectPopoutTimelines[i].hideCentered.pause();
    projectPopoutTimelines[i].hideCentered.seek(0);
  }
  projectPopoutTimelines[i].reveal.play();
  projectPopoutTimelines[i].revealCentered.play();
  console.log('opening project: ', i);
}

// POP-OUT ANIMATIONS

let projectPopoutTimelines = {};

var app = new Vue({
  el: '#interactive',
  data: {
    activeindex: -1,
    popOutType: '',
    globSoundOn: true,
  },
  watch: {
    activeindex: function (e) {
    }
  },
  created: function () {
  },
  methods: {
    // popOut: function (i, sfx, event) {
    //   // this.playSound(sfx, event);
    //   this.toggleIndex(i, event);
    // },
    // playSound: function (sfx, event) {
    //   console.log(`playSound: ${sfx}`);
    // },
    scrollTo: function (dest, event) {
      console.log(`scrollTo with dest: ${dest}`)
      let scrollDest = document.getElementById(dest);
      // scrollDest.scrollIntoView({behavior: 'smooth', block: "center"});
      animeScrollTo(scrollDest);
      scrollDest.click();
    },
    toggleSound: function (event) {
      // console.log(`toggleSound, is currently ${this.globSoundOn}`)
      // if (!audioContext ) {
      //   console.log('no audioContext, creating')
      // if (!this.globSoundOn) {
      //   audioContext = new (window.AudioContext || window.webkitAudioContext)();
      // }
      // }
      // unmute(audioContext);
      // createjs.Sound.muted = false;
      // createjs.Sound.activePlugin.context.resume();
      this.globSoundOn = !this.globSoundOn;
      if (this.globSoundOn) {
        playSound('sfx_bubbleappear')
      }
    },
    toggleIndex: function (i, event) {
      // event.preventDefault();
      if (this.activeindex == i) {
        hideProjectWithSafeguard(i);
        this.activeindex = -1;
        playSound('sfx_bubbledisappear');
      } else {
        if (this.activeindex !== -1) {
          console.log('closing another project: ', this.activeindex);
          // projectPopoutTimelines[this.activeindex].hide.play();
          hideProjectWithSafeguard(this.activeindex)
        }
        console.log('opening clicked project: ', i);
        // projectPopoutTimelines[i].reveal.play();
        revealProjectWithSafeguard(i);
        this.activeindex = i;
        if (i > lastTeamIndex) {
          this.popOutType = 'project';
        } else {
          this.popOutType = 'team';
        }
      }
    },

  },
  mounted: function () {
    this.$nextTick(function () {
      // code runs after view rendered
      headerDiv = document.querySelector('.headerContainer');
      teamDiv = document.querySelector('.teamContainer');
      projDiv = document.querySelector('.projectsContainer');
      floatingOnWaterDiv = document.querySelector('.floatingOnWater');

      createProjectPopoutTimelines();
      // createScrollScenes();
      createScrollScene(headerScene, headerAnims, headerDiv);
      createScrollScene(teamScene, teamAnims, teamDiv);
      createScrollScene(floatingOnWaterScene, floatingOnWaterAnims, floatingOnWaterDiv, '150%');
      createScrollScene(projScene, projAnims, projDiv, '300%');

      headerTargets = document.querySelectorAll('.headerTarget');
      randomizeThenDrift(headerTargets, headerAnims, headerDiv, 5, 5, false)

      teamTargets = document.querySelectorAll('.team .member');
      randomizeThenDrift(teamTargets, teamAnims, teamDiv, 10, 15)

      projTargets = document.querySelectorAll('.projects .project');
      randomizeThenDrift(projTargets, projAnims, projDiv, 11, 14)

      floatingOnWaterTargets = document.querySelectorAll('.floatingOnWater .floatingOnWaterTarget');
      randomizeThenDrift(floatingOnWaterTargets, floatingOnWaterAnims, floatingOnWaterDiv, 5, 4)

      addClickOutsideListener('popper', closePopouts);

      let clipboard = new ClipboardJS('.emailCopy');
      clipboard.on('success', function(e) {
          console.info('Action:', e.action);
          console.info('Text:', e.text);
          console.info('Trigger:', e.trigger);

          emailCopiedAnimation.play();

          e.clearSelection();
      });
      clipboard.on('error', function(e) {
          console.error('Action:', e.action);
          console.error('Trigger:', e.trigger);
      });

      let emailCopiedAnimation = anime.timeline({
        duration: 3300,
        autoplay: false,
      })
      .add({
        targets: '.textCopied',
        translateY: {
          value: '-40%',
          duration: 1000,
          easing: 'easeOutQuad',
        },
        translateX: {
          value: ['-27%', '-27%'],
          duration: 1000,
          easing: 'linear',
        },
        opacity: {
          value: [0,1],
          duration: 300,
          easing: 'easeInOutSine',
        },
      })
      .add({
        targets: '.textCopied',
        opacity: [1,0],
        duration: 300,
        easing: 'easeInOutSine',
        begin: function(anim) {
          playSound('sfx_emaildisappear');
        },
      }, 2400)
      .add({
        targets: '.textCopied',
        translateY: '-60%',
        duration: 3550,
        easing: 'easeOutQuart',
      }, 750)
      ;

      loadAllSounds();
      addPlaySoundsOnClick();

      MediaBox('.mediabox-sfx');
      MediaBox('.mediabox-trailers');
    })
  },
});

function createProjectPopoutTimelines() {

  console.log('creating project timelines');
  projectPopoutTimelines = {};
  let projects = document.querySelectorAll('.popper');

  let screenWidth = window.innerWidth;
  let halfWidth = screenWidth / 2;
  console.log(`halfWidth ${halfWidth}`);

  let scaleUp = 1.6;

  _.each(projects, function(value, i) {
    // console.log('project: ', value, ', ', i);
    projectPopoutTimelines[i] = {};

    let container = document.querySelector('.popper[data-index="'+i+'"]')
    // console.log('container width: ', container.offsetWidth);
    let elements = document.querySelectorAll('.popout[data-index="'+i+'"] > .popEl');

    let radius, step, rndmOffset;
    // let ring = 0
    // let rings = [5, 8, 11]

    // if (i > rings[ring]) {ring++;}

    // function function_name(argument) {
    //   // body...
    // }
    radius = container.offsetWidth*0.8;
    if (screenWidth > breakmd) {
      radius = container.offsetWidth*0.8;
    } else {
      radius = container.offsetWidth*0.7;
    }
    step = (2*Math.PI) / elements.length;
    rndmOffset = (2*Math.PI) * (getRandomInt(360)/360)

    let rect = container.getBoundingClientRect();
    let center = (rect.right - (rect.right - rect.left)/2)
    // console.log(`rect.right: ${rect.right}, rect.left: ${rect.left}, center ${center}`);
    let distToCenter = (-1 * (center - halfWidth));

    // console.log(`project ${i} radius: ${radius}, step: ${step}, rndmOffset: ${rndmOffset}, with calculated translateX for step 1: ${[Math.round(radius * Math.cos(rndmOffset +  step*1)), 0, ]}`)

    projectPopoutTimelines[i].hide = anime({
      targets: elements,
      translateX: function(el, i) {
        let ring = i
        return [Math.round(radius * Math.cos(rndmOffset + step*i)), 0, ];
      },
      translateY: function(el, i) {
        return [ Math.round(radius * Math.sin(rndmOffset + step*i)), 0];
      },      scale: [1,0],
      scale: [1,0],
      // opacity: [1,0],
      easing: 'easeOutCirc',
      autoplay: false,
      duration: 300,
      // begin: function(anim) {
      //   projectPopoutTimelines[i].hideCentered.seek(0);
      //   projectPopoutTimelines[i].hideCentered.play();
      // },
      complete: function(anim) {
        projectPopoutTimelines[i].reveal.pause();
        projectPopoutTimelines[i].reveal.seek(0);
      },
    });

    projectPopoutTimelines[i].hideCentered = anime({
      targets: ['.popper[data-index="'+i+'"] > .fade-art', '.popper[data-index="'+i+'"] > .proj-team'],
      translateX: [distToCenter, 0],
      scale: [scaleUp, 1],
      easing: 'easeOutCirc',
      autoplay: false,
      duration: 300,
      complete: function(anim) {
        projectPopoutTimelines[i].revealCentered.pause();
        projectPopoutTimelines[i].revealCentered.seek(0);
      }
    });

    projectPopoutTimelines[i].reveal = anime({
      targets: elements,
      translateX: function(el, i) {
        return [0, Math.round(radius * Math.cos(rndmOffset + step*i))];
      },
      translateY: function(el, i) {
        return [0, Math.round(radius * Math.sin(rndmOffset + step*i))];
      },
      scale: [0,1],
      // opacity: [0,1],
      delay: anime.stagger(50),
      duration: 500,
      easing: 'easeOutElastic(1, .5)',
      autoplay: false,
      // begin: function(anim) {
      //   projectPopoutTimelines[i].revealCentered.seek(0);
      //   projectPopoutTimelines[i].revealCentered.play();
      // },
      begin: function(anim) {
        playSound('sfx_bubbleappear');
      },
      complete: function(anim) {
        projectPopoutTimelines[i].hide.pause();
        projectPopoutTimelines[i].hide.seek(0);
        // projectPopoutTimelines[i].hideCentered.seek(0);
        // projectPopoutTimelines[i].hideCentered.pause();
      },
    });

    projectPopoutTimelines[i].revealCentered = anime({
      targets: ['.popper[data-index="'+i+'"] > .fade-art', '.popper[data-index="'+i+'"] > .gradient-bg > img', '.popper[data-index="'+i+'"] > .proj-team'],
      translateX: [0, distToCenter],
      scale: [1, scaleUp],
      duration: 500,
      easing: 'easeOutElastic(1, .5)',
      autoplay: false,
      complete: function(anim) {
        projectPopoutTimelines[i].hideCentered.pause();
        projectPopoutTimelines[i].hideCentered.seek(0);
      }
    });

  });
}

function addClickOutsideListener(className, outsideFunc) {
  document.addEventListener("click", (evt) => {
    let targetElement = evt.target;
    do {
      if (targetElement.classList && targetElement.classList.contains(className)) {
        // This is a click inside. Do nothing, just return.
        console.log('clicked inside ', className);
        return;
      }
      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);
    // This is a click outside.
    console.log('clicked outside ', className);
    outsideFunc();
  }, { passive: true });
}

function closePopouts() {
  if (app.activeindex !== -1) {
    app.toggleIndex(app.activeindex);
  }
}

// let soundFX = {}

// let soundjs = createjs.Sound;

function loadSound(url, id) {
  createjs.Sound.registerSound(url, id);
}
function playSound(id) {
  if (app.globSoundOn) {
    // unmute(audioContext);
    createjs.Sound.play(id);
  }
}

function loadAllSounds() {
  let targets = document.querySelectorAll(`[data-soundurl]`);
  console.log(`loadAllSounds:`);
  _.each(targets, function(value, i) {
    let url = value.dataset.soundurl
    let id = value.dataset.soundid
    if (url !== '' && id !== '') {
      if (url === 'teamSounds') {
        _.each(teamSounds[id], function(value, i) {
          // console.log(`teamSounds[${id}] value: ${value}, i: ${i}`);
          loadSound(value, id+i);
        })
      } else {
        loadSound(url, id);
      }
    }
  });
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

let lastTeamSoundInt;

function addPlaySoundsOnClick() {
  document.addEventListener("click", (evt) => {
    if (app.globSoundOn) {
      if (createjs.Sound.activePlugin.context !== 'running') {
        createjs.Sound.activePlugin.context.resume();
      }
      let targ = evt.target;
      let url, id;
      if (targ.dataset.soundurl) {url = targ.dataset.soundurl}
      if (targ.dataset.soundid) {id = targ.dataset.soundid}
      console.log(`clicked url: ${url}, id: ${id}`)
      // do {
        if (url && id && !targ.classList.contains('poppedOut')) {
          // This is a click inside. Do nothing, just return.
          console.log('clicked with sound', id, 'contains: ', targ.classList.contains('poppedOut'));
          if (url === 'teamSounds') {
            let length = Object.keys(teamSounds[id]).length;
            let randomInt;
            let newInt = false;
            do {
              randomInt = getRandomInt(length);
              console.log('randomInt: ', randomInt)
              if (randomInt !== lastTeamSoundInt) {
                newInt = true;
              } else {
                console.log('newInt: ', newInt, ', randomize again');
              }
            } while (!newInt);
            // let randomInt = getRandomInt(length);
            // console.log(`length of clicked teamSounds object: ${length} with randomInt ${randomInt}`);
            console.log('newInt! playing sound ', randomInt);
            lastTeamSoundInt = randomInt;
            playSound(id+randomInt);
          } else {
            playSound(id);
          }
          return;
        }
      //   // Go up the DOM
      //   targ = targ.parentNode;
      // } while (targ);
      // This is a click outside.
      console.log('clicked no sound');
      // playSound(id);
    } else {
      console.log('clicked, but sound is off');
    }
  });
}